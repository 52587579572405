<template>
  <b-tabs v-model="tabIndex">
    <b-tab title="Membresías" @click="clickTab" v-if="can('fivesclub_membership_show_membership_list')">
      <ListMemberships @set-tab-index="setTabIndex"/>
    </b-tab>
    <b-tab title="Crear" @click="clickTab" v-if="can('fivesclub_membership_show_membership_add')">
      <MembershipCreate @set-tab-index="setTabIndex"/>
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapState, mapActions, mapMutations  } from 'vuex'
import ListMemberships from '@/modules/fivesClub/components/catalogs/memberships/ListMemberships'
import MembershipCreate from '@/modules/fivesClub/components/catalogs/memberships/MembershipCreate'
import { formatCutoffs } from '@/helpers/fivesClubHelper'
import { toJson } from '@/helpers/helpers'
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl],
  components:{
    ListMemberships,
    MembershipCreate,
  },
  data() {
    return {
      tabIndex: 0
    }
  },
  computed: {
    ...mapState('fivesClubCatalogs',['seasons','selectedMembership']),
    ...mapState('auth',['user'])
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchSeasons']),
    ...mapMutations('fivesClubCatalogs',['setSeasons','setCutoffs','setSelectedMembership']),
    setTabIndex( index ){
      this.tabIndex = index
    },
    async tabCreate(){
      const seasons = await this.fetchSeasons()
      this.setSeasons( seasons )
      const cutoffs = toJson( formatCutoffs() )
      this.setCutoffs( cutoffs )
    },
    clickTab(){
      this.setSelectedMembership(null)
    }
  },
};
</script>
