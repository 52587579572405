<template>
  <b-card>
    <Memberships/>
  </b-card>
</template>

<script>
// import TypesMemberships from '@/modules/fivesClub/components/catalogs/typeMemberships/TypesMemberships'
import Memberships from '@/modules/fivesClub/components/catalogs/memberships/Memberships'


export default {
  components:{
    Memberships,
  },
};
</script>
