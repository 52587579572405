<template>
  <b-modal
    :id="'modal-descount' + membershipsInfo.id"
    :title="'Membresia: ' + membershipsInfo.code"
    ok-only
    hide-footer
  >
    <ValidationObserver ref="createMembershipDescount" v-slot="{ invalid }">
      <b-form @submit.prevent="save">
        <b-row class="border rounded p-1">
          <b-col md="12">
            <h4>Categorias</h4>
            <b-input
              v-model="filter"
              placeholder="Filtros para la tabla.."
            ></b-input>
            <hr />
            <b-table
              responsive
              :filter="filter"
              :items="categories"
              :fields="fields"
              class="mb-0"
              v-if="categories.length"
            >
              <!-- Optional default data cell scoped slot -->

              <template #cell(descount)="row">
                <b-form-group>
                  <b-form-input
                    class="form-control"
                    type="number"
                    v-model="row.item.descount"
                    :disabled="membershipsInfo.temporalDataStatus"
                  >
                  </b-form-input>
                </b-form-group>
              </template>

              <template #cell(status)="row">
                <div class="">
                  <b-form-checkbox
                    v-model="row.item.state"
                    :disabled="membershipsInfo.temporalDataStatus"
                    :value="true"
                    switch
                    inline
                    unchecked-value="false"
                    @change="optionSelectinCategory(row.item, $event)"
                    class="custom-control-success"
                  >
                  </b-form-checkbox>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <div class="float-right">
              <b-button
                v-if="!membershipsInfo.temporalDataStatus"
                type="submit"
                class="btn-block"
                variant="primary"
                :disabled="invalid"
                v-can="'fivesclub_membership_show_membership_list_action_button_edit_payment_save_button'"
              >
                <b-spinner small v-if="isSavingMembership" /> Guardar
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { showAlertMessage } from "@/helpers/helpers";
import { createArraysTocategory } from "@/helpers/fivesClubHelper";

export default {
  props: {
    membershipsInfo: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filter: null,
      fields: [
        { key: "name", label: "Nombre" },
        { key: "descount", label: "Descuento" },
        { key: "status", label: "Aplicar" },
      ],
      membershipCategory: [],
      isUpdating: false,
      cancelacióntext: "",
      isSavingMembership: "",
      status: true,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("fivesClubCatalogs", ["paymentTypes", "retornos"]),
    ...mapState("fivesClubmembershipsInfos", ["membershipsInfostatus"]),
    showStatusmembershipsInfo() {
      return this.membershipsInfostatus;
    },
  },
  methods: {
    ...mapActions("fivesClubCatalogs", ["saveCategoriesMembership"]),

    optionSelectinCategory(option, e) {
      const bool = e == true ? true : false;
      console.log(bool);
      if (bool == true) {
        this.membershipCategory.push(option);
      } else {
        this.membershipCategory = this.membershipCategory.filter(
          (b) => b.id !== option.id
        );
      }
    },
    async save() {
      const category = createArraysTocategory(this.categories,this.membershipCategory);
      const payload = {
        idUser: this.user.idUser,
        idMembership: this.membershipsInfo.id,
        membershipCategory: category,
      };

      const { status, message } = await this.saveCategoriesMembership(payload);

      if (status) {
        showAlertMessage(
          "Ok: proceso finalizado",
          "InfoIcon",
          message,
          "success",
          4000,
          "bottom-right"
        );
        this.membershipCategory = [];
        this.$root.$emit(
          "bv::hide::modal",
          "modal-descount" + this.membershipsInfo.id
        );
      }
    },
  },
};
</script>
